import React from 'react';
import './Header.css';
import image from './img/logo.png';
import {Link} from 'react-router-dom';


function Header () {

    return (
        <section className="header">
            <div className="hader-logo">
                <Link to="/">
                    <img src={image} width={350} alt='logo'/>
                </Link>
            </div> 
            
        </section>
    

    )
}

export default Header;