import React from "react";
import './Privacy.css';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';


const Privacy = () => {

    return (
    <Container fluid="md">
        <Col>
        <Row className="justify-content-md-center">
        <Card body>
            <Card.Header>
                 <Card.Title> POLITIKA VAROVANJA ZASEBNOSTI UPORABNIKA MOBILNE APLIKACIJE ViziDrive </Card.Title>
                </Card.Header>
                 <br/>
                <div className='Title-Terms'>
                    <Card.Header>
                <Card.Title >1. UVODNE DOLOČBE</Card.Title>
                </Card.Header>
                </div>
            <Card.Text>
                <br/>
                <p>Vizije mobilnosti d.o.o., Pot na Polane 22, 1351 Brezovica pri Ljubljani (v nadaljevanju: Vizije mobilnosti) obdelujejo osebne podatke v skladu z Uredbo (EU) 2016/679 Evropskega parlamenta in Sveta z dne 27. aprila 2016 o varstvu posameznikov pri obdelavi osebnih podatkov in o prostem pretoku takih podatkov ter o razveljavitvi Direktive 95/46/ES (v nadaljevanju: Splošna uredba o varstvu podatkov ali GDPR) in nacionalnimi predpisi o varstvu osebnih podatkov.</p> 
                </Card.Text>
                <br/>
                <Card.Header>
                    <Card.Title>1. VRSTE OSEBNIH PODATKOV, PODLAGA TER NAMEN OBDELAVE</Card.Title>
                </Card.Header>
                <br/>
                        <Card.Text>
           
<p> Ob registraciji v mobilni aplikaciji ViziDrive mora uporabnik posredovati Vizijam mobilnosti svoje osebne podatke:</p>
        <div className="first-1">  
<p className="p-1"> - naslov elektronske pošte,</p>
<p className="p-1"> - ime in priimek,</p>
<p className="p-1"> - mobilna telefonska številka (podatek ni obvezen)</p>
</div>   
<br/>
<p>Pri uporabi mobilne aplikacije ViziDrive bodo Vizije mobilnosti, poleg zgoraj navedenih osebnih podatkov, obdelovale tudi podatke o naročilih, lokaciji uporabnika in kameri naprave.</p>
<p>Vizije mobilnosti bodo navedene podatke obdelovale za naslednje namene:</p>
<div className="first-2">
<p className="p-2"> - zagotavljanje storitev, ki so predmet splošnih pogojev,</p>
<p className="p-2"> - registracije uporabnika, </p>
<p className="p-2"> - slikanja QR kode na polnilni infrastrukturi,</p>
<p className="p-2"> - kontaktiranje uporabnika za zagotavljanje nemotene uporabe storitev, ki so predmet teh splošnih pogojev,</p>
<p className="p-2"> - reševanja reklamacij.</p>
</div>
<p>Za nemoteno uporabo mobilne aplikacije ViziDrive bo moral uporabnik podati dovoljenje za obdelavo informacij glede svoje lokacije, z namenom uporabe funkcionalnosti iskanja najbližje polnilnice, in dovoljenje za uporabo kamere, za slikanje QR kode na polnilnici. V kolikor uporabnik mobilne aplikacije teh dovoljenj ne bo podal, navedenih funkcionalnosti ne bo mogel uporabljati.</p>
<p>Za namene izvedbe storitve Vizije mobilnosti obdelujejo podatke o opravljeni storitvi - kraj, datum in čas opravljene storitve in uporabljeni QR kodi na polnilnici, z namenom prikaza zgodovine polnjenja.</p>
<p>Navedene podatke bodo Vizije mobilnosti obdelovale za druge namene, le v primeru, da je uporabnik za njih podal izrecno pisno soglasje:</p>
<div className="first-3">
<p className="p-3"> - profiliranje uporabnikov za pripravo prilagojenih ponudb svojih storitev prek uporabe naslova e-pošte, SMS, MMS, telefona ali klasične pošte,</p>
<p className="p-3"> - neposredno trženje storitev, ugodnosti in drugih sprememb oz. novosti, </p>
<p className="p-3"> - izvajanje tržnih raziskav o kvaliteti storitev ter potrebah po dodatnih storitvah, </p>
<p className="p-3"> - pošiljanje potisnih sporočil v skladu z nastavitvami potisnih sporočil znotraj mobilne aplikacije ViziDrive ali nastavitvami pametnega telefona ali tabličnega računalnika.</p>
</div>
    </Card.Text>
            <br/>
                <Card.Header>
                <Card.Title> 2. SPREMEMBE PODATKOV</Card.Title>
    </Card.Header>
            <br/>
                    <Card.Text>
                        <p>V primeru, da se osebni podatki iz točke 1 spremenijo, mora uporabnik sporočiti Vizijam mobilnosti spremembo v roku 30 dni od njenega nastanka, in sicer na e-mail naslov: info@vizidrive.eu, preko mobilne aplikacije ViziDrive ali pisno na naslov: Vizije mobilnosti d.o.o., Pot na Polane 22, 1351 Brezovica pri Ljubljani. </p>
                        <p>Sprememba podatkov, posredovanih preko mobilne aplikacije ViziDrive bo upoštevana takoj, sprememba podatkov, ki bo posredovana po navadni ali elektronski pošti, pa bo upoštevana najkasneje v roku 5 dni od dne prejema pisnega obvestila. </p>
                    </Card.Text>   
            <br/>
                    <Card.Header>
                <Card.Title> 3. PRAVICE UPORABNIKA</Card.Title>
                </Card.Header>
            <br/>
                    <Card.Text>
                        <p>Uporabnik lahko kadarkoli zahteva potrditev, ali Vizije mobilnosti obdelujejo njegove osebne podatke ter seznanitev s temi osebnimi podatki. Prav tako ima pravico, da te osebne podatke prejme in da jih posreduje drugemu upravljavcu. Uporabnik lahko zahteva omejitev obdelave osebnih podatkov, ugovarja obdelavi osebnih podatkov, prekliče dana soglasja za različne namene obdelave, zahteva popravke oz. dopolnitve ali izbris osebnih podatkov. Vse navedene pravice lahko uveljavlja pod pogoji in v obsegu iz 15. do 22. člena GDPR.  </p>
                        <p>Ne glede na uveljavitev pravic iz prejšnjega odstavka, se bodo osebni podatki morda obdelovali v okviru morebitnih ostalih danih privolitev ter v primeru, da za obdelavo obstaja skladno s 6. členom GDPR druga pravna podlaga, v okviru te podlage.  </p>
                        <p>Zahtevo za uveljavitev pravic iz prvega odstavka te točke, lahko uporabnik izvede s pisno zahtevo, poslano na naslov Vizije mobilnosti d.o.o., Pot na Polane 22, 1351 Brezovica pri Ljubljani, s pripisom »za DPO«. Vizije mobilnosti bodo v primeru uveljavljanja uporabnikovih pravic glede ugovora obdelave podatkov, preklica soglasja, zahteve po omejitvi obdelave ali izbrisu osebnih podatkov, ki imajo za posledico preprečevanje obveščanja uporabnika, preprečile obveščanje najkasneje v roku 15 dni od prejema ugovora oziroma spremembe nastavitev uporabnika. Uveljavljanje ostalih pravic bodo Vizije mobilnosti zagotavljale v rokih, zahtevanih z veljavno zakonodajo s področja varstva osebnih podatkov. </p>
                    </Card.Text>    
            <br/>
                    <Card.Header>
                <Card.Title> 4. ROK HRAMBE OSEBNIH PODATKOV</Card.Title>
                </Card.Header>
            <br/>
                    <Card.Text>
                        <p>Osebni podatki se obdelujejo do izpolnitve namena obdelave oziroma v okviru zastaralnih rokov za obveznosti, ki bi lahko izvirale iz obdelave teh osebnih podatkov. V primerih, ko je rok hrambe osebnih podatkov določen z zakonom, Vizije mobilnosti te osebne podatke hranijo v skladu z zakonsko določbo.</p>
                        <p>Osebni podatki, ki so pridobljeni na podlagi soglasij, se hranijo do preklica. Preklic obdelave osebnih podatkov uporabniki lahko sporočijo na e-mail naslov: <a href="mailto:info@vizidrive.eu">info@vizidrive.eu</a> ali pisno na naslov: Vizije mobilnosti d.o.o., Pot na Polane 22, 1351 Brezovica pri Ljubljani.</p>
                    </Card.Text> 
    </Card>
  </Row>
  </Col>
  <br/>
</Container>
    )
}
export default Privacy;