import React from "react";
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import './Faq.css';


const FaqEn = () => {
    return (
        <Container fluid="md">
        <Col>
        <Row className="justify-content-md-center">
        <Card body className="body">
            <Card.Header>
                 <Card.Title>General</Card.Title>
                </Card.Header>
                <br/>
            <Card.Text>
                <h5>1. Is the charging service chargeable?</h5>
                <p>The charging service is currently for all registered users on the charging infrastructure managed by Vizije mobilnosti d.o.o. free. Use of the Services is subject to the General Terms of Use and Privacy Policy.</p> 
                <h5>2. Which means of payment are supported?</h5>
                <p>The charging service is currently managed by Vizije mobilnosti d.o.o. for all registered users of the charging infrastructure free.</p>
                <h5>3. Can I use the ViziDrive mobile application without registration?</h5>
                <p>In accordance with the current general terms of use, the basic functionality of the ViziDrive mobile application is enabled without registration. However, the use of advanced functionalities and functionalities linked to the user's user account requires user registration and login.</p>
                <h5>4. Where can I access the general terms of use and privacy policy?</h5>
                <p>The General Terms of Use and Privacy Policy are published on the website <a href="www.vizidrive.eu"> www.vizidrive.eu </a>.</p>
                </Card.Text>
            
                <br/>
                <Card.Header>
                    <Card.Title>Charging infrastructure</Card.Title>
                </Card.Header>
                <br/>
                <Card.Text>
                    <h5>1. What should I do if the charging station is not working or is damaged?</h5>
                    <p>In the event of visible damage to any part of the charging station (charging station housing, connection connector or connection cable), the use of the charging station is not permitted. To report a bug and further information, please contact the ViziDrive team.</p>
                    <h5>2. What to do if the surroundings of the pouring station are untidy or do not match the description in the mobile application?</h5>
                    <p>In case of disorder in the surroundings of the charging station or if the description of the location of the charging station does not correspond to the actual situation, please inform the ViziDrive team.</p>
                    <h5>3. What I do if I have additional questions?</h5>
                    <p>If you have any further questions or clarifications, please contact the ViziDrive team.</p>
  
                </Card.Text>
            <br/>
                <Card.Header>
                <Card.Title>ViziDrive team</Card.Title>
                </Card.Header>
            <br/>
                    <Card.Text>
                    <h5> 1. Who is the ViziDrive team? </h5>
                        <p> The ViziDrive brand is managed by Vizije mobilnosti d.o.o., Pot na Polane 22, 1351 Brezovica pri Ljubljani, Slovenia. </p>
                        <p> website: www.vizije-mobilnosti.si </p>
                        <p>
                              website: <a href="www.vizije-mobilnosti.si" target="_blank"> www.vizije-mobilnosti.si </a>
                              <br />
                              email: <a href="mailto:info@vizije-mobilnosti.si"> info@vizije-mobilnosti.si </a>
                        </p>
                        <h5> 2. How do I contact the ViziDrive team? </h5>
                        <p> Contact details: </p>
                        <p> tel: +386 51 754 407 </p>
                        <p> email: <a href="mailto:info@vizidrive.eu"> info@vizidrive.eu </a> </p>
                        <p> Website: <a href="www.vizidrive.eu" target="_blank"> www.vizidrive.eu </a> </p>
                    </Card.Text>   
            <br/>
                    <Card.Header>
                <Card.Title>Vehicle charging</Card.Title>
                </Card.Header>
            <br/>
                    <Card.Text>
                    <h5> 1. Who is charging for? </h5>
                        <p> Vehicle charging is intended for all owners or users of battery electric vehicles (BEVs) and hybrid electric vehicles (PHEVs). </p>
                        <h5> 2. Why can't I start charging? </h5>
                        <p> User registration and application login are required before charging can begin.
                            If charging is still not possible, make sure the charging cable is installed correctly and you have access to mobile data on your mobile device.
                            In case of application problems with charging start, the application reports the type and description of the charging error. For further clarification, you can contact the ViziDrive team, and ask for a solution according to the type or description of the error
                        </p>
                        <h5> 3. Why doesn't my vehicle charge at maximum power? </h5>
                        <p> Charging power depends on several factors. The most common factors limiting charging power are: </p>
                        <p> - cable type <br /> <br />
                            The cable used to charge the vehicle does not allow charging at the available charging power of the station,
                            </p>
                        <p> - Dynamic charge power adjustment <br /> <br />
                                The charging station dynamically regulates the charging power according to the currently available electrical power and the occupancy of the charging stations at the same location,
                            </p>
                        <p> - Vehicle charging power limit <br /> <br />
                            The vehicle limited the maximum charging power in order to protect the battery and other key components of the vehicle. <br />
                            If you have any further questions or clarifications, please contact the ViziDrive team
                        </p>

                        <h5> 4. Why can't I finish charging? </h5>
                        <p> Make sure you have access to mobile data on your mobile device. <br />
                            In case of application problems with charging completion, the application reports the type and description of the charging error. For further clarification, you can contact the ViziDrive team, and ask for a solution according to the type or description of the error.
                            </p>
                        <h5> 5. What should I do if the charging cable remains locked in the charging station or vehicle after charging? </h5>
                        <p> After charging, the cable must first be disconnected from the vehicle and only then from the charging station. If the cable cannot be disconnected from the vehicle, check that the charging in the application is completed and follow the instructions for use of your vehicle. <br />
                            In case of additional problems, contact the ViziDrive team.
                            </p>
                        <h5> 6. What do I do if I have additional questions? </h5>
                        <p> If you have any further questions or clarifications, please contact the ViziDrive team. </p>
                    
                    </Card.Text>    
            <br/>
                    <Card.Header>
                <Card.Title>General Terms of Use and Privacy Policy</Card.Title>
                </Card.Header>
            <br/>
                    <Card.Text>
                        <p><a href="https://app.vizidrive.eu/content/terms-of-service/en">1. General conditions</a></p>
                        <p><a href="https://app.vizidrive.eu/content/privacy-policy/en">2. Privacy policy</a></p>
                    </Card.Text> 
                    <br/>
                    <Card.Header>
                <Card.Title>User accounts</Card.Title>
                </Card.Header>
            <br/>
            <Card.Text>
            <h5> 1. What can I do if I forget my password? </h5>
                        <p> You can reset your password via the ViziDrive mobile app <br />
                           If you have any further questions or clarifications, please contact the ViziDrive team </p>
                        <h5> 2. What should I do if I suspect that my account has been misused? </h5>
                        <p> In case of suspicion of misuse of the user account, immediately reset the password via the mobile application ViziDrive.
                            If you have any further questions or clarifications, please contact the ViziDrive team.</p>
                        <h5> 3. What do I do if I have additional questions? </h5>
                        <p> If you have any further questions or clarifications, please contact the ViziDrive team. I can't find the answer to my question
                        </p>
                        <h5> 1. Contact details of the ViziDrive team </h5>
                        <p> tel: +386 51 754 407 <br />
                           email: <a href="mailto:info@vizidrive.eu"> info@vizidrive.eu </a> <br />
                           website: <a href = "www.vizidrive.eu"> www.vizidrive.eu </a>
                        </p>
                    </Card.Text> 

    </Card>
  </Row>
  </Col>
  <br/>
</Container>
 
    )
}

export default FaqEn;


