import React from 'react';
import { Grid, Card,  Avatar,  Typography, Button, MobileStepper, CardMedia } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';
import Favicon from '../Pages/img/favicon.png';
import Korak1 from '../Pages/img/Korak1.png';
import Korak2 from '../Pages/img/Korak2.png';
import Korak3 from '../Pages/img/Korak3.png';
import Korak4 from '../Pages/img/Korak4.png';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@emotion/react';
import { autoPlay } from 'react-swipeable-views-utils';
import { Link} from 'react-router-dom';



const AutoPlaySwipeableViews = autoPlay(SwipeableViews);


const images = [
    
   {
        label1: 'Korak 1',
        label2: 'Izberite tip priključka',
        label3: 'Če polnilnica nima integriranega kabla za polnjenje na želenem priključku, morate za polnjenje uporabiti lasten kabel.',
        imgPath: Korak1,
    },


    {
      label1: 'Korak 2',
      label2: 'Priključite vozilo na polnilno postajo',
      label3: 'Pred pričetkom polnjenja mora biti vozilo z ustreznim kablom priključeno na polnilno postajo.',
        imgPath: Korak2,
    },

    {
        label1: 'Korak 3',
        label2: 'Skenirajte QR kodo na izbranem priključku za polnjenje',
        label3: 'QR koda se nahaja nad izbranim priključkom na polnilni postaji. Aplikacija omogoča tudi ročni vnos kode priključka, ki je zapisan poleg QR kode.',
        imgPath: Korak3
    },

    {
      label1: 'Korak 4',
      label2: 'Zaključite polnjenje',
      label3: 'Ko je vozilo napolnjeno oziroma želite zaključiti polnjenje, vozilo odklopite in prepustite polnilno mesto drugemu uporabniku.',
        imgPath: Korak4,
    },
]


const useStyles = makeStyles((theme) =>
 createStyles({
    root: {
    flexGrow: 1,
    backgroundColor: '#E4F2D0',
    maxWidth: '444px',
    minWidth: '300px',
    margin: 'auto',
    justifyItems: 'center'

}, 

progressbar:{
    '& .MuiLinearProgress-barColorPrimary ':{
        backgroundColor: '#000000'
    },
    margin: '40px 10px 0 10px'
},
// Card 
paper:{
    color: theme.palette.text.secondary,
      borderRadius: '32px',
      height: '380px',
      margin: '60px 18px 0 18px',
      //backgroundImage: {imgPath},
      backgroundPositionX: 'center',
      backgroundPositionY: 'center',
      backgroundSize: 'cover',
      justifyContent: 'center'
    
}, 

// Avatar - logo
avatarcss:{
    '& .MuiAvatar-img':{
        width: '58%',
        height: '58%',
        marginLeft: '10px',
        marginTop: '6px'
    }
},
row:{
    marginTop: '20px'
},

typo:{
  color: theme.palette.text.secondary,
    '& .MuiTypography-subtitle1':{
        paddingTop: '10px',
        fontFamily: 'basier_circleregular',
        marginLeft: '-10px',
        fontSize: '13px',
        
        
    }
},
// Title above body
bodytitle:{
    marginTop: '14px',
    marginLeft: '14px',
    textAlign: 'left',
    marginBottom:'28px'
    
},

// Card - logo
acard: {
    color: theme.palette.text.secondary,
    margin: '16px 0 0 16px',
    backgroundColor: '#FAE6DF',
    fontFamily: 'basier_circlemedium'
   
},

mobisteper: {
        marginTop: '20px',
        '& .MuiMobileStepper-root':{
        backgroundColor: '#E4F2D0'
        },
        '& .MuiMobileStepper-dotActive':{
        backgroundColor: '#0e0e0e'
        }
},

  }));


export const Know = () => {
  
  const theme = useTheme();
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = images.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };
    return (
    
            <Grid className={classes.root} key={1}> 
                <Grid container item direction='flex' xs={12} spacing={1} className={classes.row}>
                    < Grid item xs={2}>
                    <Avatar className={classes.avatarcss} src={Favicon}></Avatar>
                    </Grid>
                    < Grid item xs={8} className={classes.typo}>
                    <Typography variant='subtitle1'>Navodila za polnjenje</Typography>
                    </Grid>
                    <Grid item xs={2}>
                    <Button component={Link} to={'/content/quick-guide/si'}
                                >
                    <CloseIcon  className='icon'  style={{textAlign: 'right', margin:'2px 20px 0px 8px'}} /> 
                    </Button>
                    </Grid>
                </Grid>
                <div className={classes.bodytitle}>
                    <Typography variant='body1' style={{fontFamily: 'basier_circlesemibold', fontSize: '20px'}}>{images[activeStep].label1}</Typography>
                    <Typography variant='body1' style={{fontFamily: 'basier_circlesemibold', fontSize: '13px', marginTop: '20px', textJustify: 'auto'}}>{images[activeStep].label2}</Typography>
                    <Typography  variant='subtitle2' style={{fotnFamily: 'basier_circleregular', marginTop: '20px', fontSize: '12px', textJustify: 'auto', position:'absolute'}}>{images[activeStep].label3}</Typography>
                </div>
    
      
       <AutoPlaySwipeableViews
        
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
        {images.map((step, index) => (
          <div key={step.label}>
            {Math.abs(activeStep - index) <= 2 ? (
              <Card className={classes.paper} >

                  <CardMedia              
                    component='img'
                    image={step.imgPath}
                    height='100%'
                  >
                  </CardMedia>

              </Card>
            
            ) : null}
          </div>
        ))}
      </AutoPlaySwipeableViews>
      <div className={classes.mobisteper}>
      <MobileStepper
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={
          <Button
            size="small"
            onClick={handleNext}
            disabble={activeStep === maxSteps - 1} >
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disable={activeStep === 0}>
          </Button>
        }
      />
      </div>
    </Grid>
  );
}

export default Know;