import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Grid, Paper, Avatar, Typography, Button, Card} from '@material-ui/core';
import Heart from '../Header/img/icon-knw.svg';
import Charges from '../Header/img/icon-chr.svg';
//import Reserve from '../Header/img/icon-rsv.svg';
import Register from '../Header/img/icon-reg.svg';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      paddingTop:"10px",
      paddingLeft: "20px",
      paddingRight: "20px",
      backgroundColor: '#ECF2F1'
      
      
    },
    paper: {
      padding: theme.spacing(2),
      color: theme.palette.text.secondary,
      borderRadius: '32px',
      height: '180px'
    },

    btn: {
        borderRadius: "24px",
        textTransform: "initial",
        justifyContent: "center",
        marginTop: "38px",
        ['@media (max-width:580px)']: { // eslint-disable-line no-useless-computed-key
          width: '100%'
        },
        fontFamily: 'basier_circlesemibold',
        height: "46px",
        fontSize: "16px",
        backgroundColor: '#F2F2F2'
    },

    title: {
      fontSize: "16px",
      fontFamily: 'basier_circleregular',
      color: 'black'
    },

    titleh: {

      fontFamily: 'basier_circlemedium',
      paddingLeft: "6px",
      paddingTop: "12px",
      color: 'black'
      
    }

  }),
);

export default function Guide () {
    const classes = useStyles();
return (
  <>
  <div>
  <div className={classes.root}>
    <Typography className={classes.titleh} variant='h5'>Hitri vodnik</Typography>
  </div>
  </div>
<div className={classes.root}>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <Card className={classes.paper}>
              <Avatar img src={Heart}></Avatar>
              <Typography className={classes.title} variant='subtitle2'>Spoznaj me</Typography>
              <Button className={classes.btn} variant="contained">Več o tem</Button>
          </Card>
        </Grid>

        <Grid item xs={6}>
          <Card className={classes.paper}>
          <Avatar img src={Register}></Avatar>
          <Typography className={classes.title} variant='subtitle2'>Registracija</Typography>
          <Button className={classes.btn} variant="contained">Več o tem</Button>
          </Card>
        </Grid>
        
        
        
{ /*
        <Grid item xs={6}>
          <Paper className={classes.paper}>
          <Avatar img src={Reserve}></Avatar>
          <Typography className={classes.title} variant='subtitle2'>How to Reserve</Typography>
          <Button className={classes.btn} variant="contained">View more</Button>
          </Paper>
        </Grid>
*/ }
       
        <Grid item xs={6}>
          <Paper className={classes.paper}>
          <Avatar img src={Charges}></Avatar>
          <Typography className={classes.title} variant='subtitle2'>Kako napolniti</Typography>
          <Button className={classes.btn} variant="contained" a target="_blank" href="/content/quick-guide/know/si" id="bt">Več o tem</Button>
          </Paper>
        </Grid>
      </Grid>
    </div>
    </>
  );
}
