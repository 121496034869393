import React from "react";
import './Privacy.css';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';


const PrivacyEn = () => {

    return (
    <Container fluid="md">
        <Col>
        <Row className="justify-content-md-center">
        <Card body>
            <Card.Header>
                 <Card.Title> ViziDrive MOBILE APPLICATION PRIVACY POLICY </Card.Title>
                </Card.Header>
                 <br/>
                <div className='Title-Terms'>
                    <Card.Header>
                <Card.Title >1. INTRODUCTORY PROVISIONS</Card.Title>
                </Card.Header>
                </div>
            <Card.Text>
                <br/>
                <p>Vizije mobilnosti d.o.o., Pot na Polane 22, 1351 Brezovica pri Ljubljani (hereinafter: Vizije mobilnosti) processes personal data in accordance with Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 on the protection of individuals with regard to the processing of personal data and on the free movement of such data and on the repeal of Directive 95/46 / EC (hereinafter referred to as the General Data Protection Regulation or GDPR) and national rules on the protection of personal data.</p> 
                </Card.Text>
                <br/>
                <Card.Header>
                    <Card.Title>1. TYPES OF PERSONAL DATA, BASIS AND PURPOSE OF PROCESSING</Card.Title>
                </Card.Header>
                <br/>
                        <Card.Text>
           
<p> When registering in the ViziDrive mobile application, the user must provide Mobility Visions with their personal data:</p>
        <div className="first-1">  
<p className="p-1"> - email address,</p>
<p className="p-1"> - First name and Last name ,</p>
<p className="p-1"> - mobile phone number (optional)</p>
</div>   
<br/>
<p>When using the ViziDrive mobile application, Visions of Mobility, in addition to the above personal data, will also process data on orders, user location, and the camera of the device.</p>
<p>Visions of mobility will process this data for the following purposes:</p>
<div className="first-2">
<p className="p-2"> - the provision of services subject to the general conditions,</p>
<p className="p-2"> - user registration, </p>
<p className="p-2"> - QR code imaging on the charging infrastructure,</p>
<p className="p-2"> - contacting the user to ensure the smooth use of the services subject to these general terms and conditions,</p>
<p className="p-2"> - resolving complaints.</p>
</div>
<p>For the smooth use of the ViziDrive mobile application, the user will have to provide permission to process information about their location, in order to use the functionality of finding the nearest charging station, and permission to use the camera to capture QR code at the charging station. If the user of the mobile application does not give these permissions, he will not be able to use the stated functionalities.</p>
<p>For the purposes of performing the service, Visions of Mobility processes data on the service provided - place, date and time of the service provided and QR codes used at the filling station, in order to display the history of filling.</p>
<p>The mentioned data will be processed by Visions of Mobility for other purposes, only if the user has given his explicit written consent:</p>
<div className="first-3">
<p className="p-3"> - profiling users to prepare customized offers of their services through the use of e-mail, SMS, MMS, telephone or traditional mail,</p>
<p className="p-3"> - direct marketing of services, benefits and other changes or news, </p>
<p className="p-3"> - conducting market research on the quality of services and the need for additional services, </p>
<p className="p-3"> - send messages according to push message settings within the ViziDrive mobile app or smartphone or tablet settings.</p>
</div>
    </Card.Text>
            <br/>
                <Card.Header>
                <Card.Title>2. CHANGES IN DATA</Card.Title>
    </Card.Header>
            <br/>
                    <Card.Text>
                        <p>In the event that the personal data referred to in point 1 changes, the user must notify Visions of Mobility of the change within 30 days of its creation, by e-mail: info@vizidrive.eu, via the mobile application ViziDrive or in writing to: Visions mobilnosti d.o.o., Pot na Polane 22, 1351 Brezovica. </p>
                        <p>Changes to data transmitted via the ViziDrive mobile application will be considered immediately, and changes to data transmitted by regular mail or e-mail will be considered no later than 5 days from the date of receipt of the written notice. </p>
                    </Card.Text>   
            <br/>
                    <Card.Header>
                <Card.Title> 3. USER RIGHTS</Card.Title>
                </Card.Header>
            <br/>
                    <Card.Text>
                        <p>The user may at any time request confirmation of whether Visions of Mobility processes his personal data and acquaintance with this personal data. He also has the right to receive this personal data and to pass it on to another controller. The user may request a restriction on the processing of personal data, object to the processing of personal data, revoke the given consents for various purposes of processing, request corrections or. supplementing or deleting personal data. He may exercise all the above rights under the conditions and to the extent set out in Articles 15 to 22 of the GDPR.  </p>
                        <p>Notwithstanding the exercise of the rights referred to in the previous paragraph, personal data may be processed within the framework of any other consents given and, if there is another legal basis for processing in accordance with Article 6 of the GDPR, within this basis.  </p>
                        <p>The request for exercising the rights from the first paragraph of this point can be made by the user with a written request sent to the address Vizije mobilnosti d.o.o., Pot na Polane 22, 1351 Brezovica near Ljubljana, with the annotation "for DPO". Visions of mobility will prevent the notification within 15 days of receiving the objection or changing the user's settings in case of exercising the user's rights regarding the data processing objection, revocation of consent, requests to limit the processing or deletion of personal data, which result in preventing the user from being informed. Exercising other rights will be ensured by the Vision of Mobility within the deadlines required by the applicable legislation in the field of personal data protection.</p>
                    </Card.Text>    
            <br/>
                    <Card.Header>
                <Card.Title> 4. DEFINITION PERIOD OF PERSONAL DATA</Card.Title>
                </Card.Header>
            <br/>
                    <Card.Text>
                        <p>Personal data are processed until the purpose of processing is met or within the limitation periods for obligations that could arise from the processing of such personal data. In cases where the retention period of personal data is determined by law, the Vision of Mobility retains this personal data in accordance with the legal provision.</p>
                        <p>Personal data obtained on the basis of consents shall be kept until revoked. The cancellation of the processing of personal data can be reported by users to the e-mail address: <a href="mailto:info@vizidrive.eu"> info@vizidrive.eu </a> or in writing to the address: Vizije mobilnosti d.o.o., Pot na Polane 22, 1351 Brezovica near Ljubljana.</p>
                    </Card.Text> 
    </Card>
  </Row>
  </Col>
  <br/>
</Container>
    )
}
export default PrivacyEn;