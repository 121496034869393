import React from 'react';
import './body.css';
import seca from './img/secimg.png';
import img2 from './img/treeimg.png';
import img3 from './img/mobile1.png';
import img4 from './img/mobile2.png';
import MailchimpSubscribe from 'react-mailchimp-subscribe';


const url = "https://vizidrive.us5.list-manage.com/subscribe/post?u=5b5d33bbc7c195bbeb09fa610&amp;id=50e75c6cc8";

const imageUrl = window.innerWidth >= 650 ? seca : img3;
const imgMobile = window.innerWidth >= 650 ? img2 : img4;
const SimpleForm = () => <MailchimpSubscribe url={url}/>
const CustomForm = () => (
    <MailchimpSubscribe
      url={url}
      render={({ subscribe, status, message }) => (
        <div>
          <SimpleForm onSubmitted={formData => subscribe(formData)} />
          {status === "sending" && <div style={{ color: "blue" }}>sending...</div>}
          {status === "error" && <div style={{ color: "red" }} dangerouslySetInnerHTML={{__html: message}}/>}
          {status === "success" && <div style={{ color: "green" }}>Subscribed!</div>}
        </div>
      )}
    />
  )
const Home = () => {

    return (
        <>
        <section className="body">
         <div className="body-img" style={{ backgroundSize: 'cover', backgroundImage: `url(${imageUrl})`}}>
            <div className='top-title'>
                <h1>Dobrodošli na <span>{<br/>}platformi ViziDrive!</span></h1>
                <p>Polnilnice v naši mreži se trenutno <span>{<br />}uprabljajo na način priključi in polni.</span></p>
           </div>
         </div>
        </section>
        <section className="mid-sec">
           <div className="body-img" style={{ backgroundSize: 'cover', backgroundImage: `url(${imgMobile})`}}>
           <div className="mid-title"> 
           <h1>Z vami od<span>{<br/>}1.1.2022 naprej</span></h1>
           <p>Platforma ViziDrive omogoča storitev e-mobilnosti na enem mestu. Za svoje uporabnike nudi meržo polnilnic, katere je enostavno najti, polniti in storitev tudi plačati kar znotraj platforme.</p>
            </div>
            </div>
        </section>
          <div className="mobi-div">
              <h1>Z vami od<span>{<br/>}1.1.2022 naprej</span></h1>
              <p>Platforma ViziDrive omogoča storitev e-mobilnosti na enem mestu. Za svoje uporabnike nudi meržo polnilnic, katere je enostavno najti, polniti in storitev tudi plačati kar znotraj platforme.</p>
          </div>
        <section className="tre-sec">
            <div className="l-sec">
                <div className="title-l">
                <h1>Prijavite se na e-novice</h1>
                <p>Zaupajte nam svoje podatke in spremljajte<span>{<br/>}razvoj platforme ViziDrive.</span></p>
                <div className="input-email">
                    <CustomForm />    
                </div>
            </div>
            </div>

        </section>

     </>
    )
}

export default Home;

