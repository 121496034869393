import React from 'react';
import './footer.css';
import image from './img/f-logo.png';

const Footer =() => {

    return (
        <section className="sec-footer">
            <div className="fo-con" >
                <img className="img-logo" src={image}  alt='log-f'/> 
                <div className="copy-r">
                    <p>&copy; 2021 ViziDrive</p>
                </div>
            </div>
        </section>
    
    )
}

export default Footer ;