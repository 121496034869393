import React from 'react';
import './Terms.css';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';

const Terms = () => {
    return (
        <Container fluid="md">
        <Col>
        <Row className="justify-content-md-center">
        <Card body>
            <Card.Header>
                 <Card.Title> SPLOŠNI POGOJI UPORABE MOBILNE APLIKACIJE VizDrive</Card.Title>
                </Card.Header>
                <br/>
                <div className='Title-Terms'>
                    <Card.Header>
                <Card.Title >1. UVODNE DOLOČBE</Card.Title>
                </Card.Header>
                </div>
        <br/>
            <Card.Text>
                <p>S splošnimi pogoji uporabe mobilne aplikacije ViziDrive (v nadaljevanju: splošni pogoji) Vizije mobilnosti d.o.o. določajo obveznosti, pravice in pogoje za uporabo aplikacije ViziDrive.</p> 
                <p>Ti splošni pogoji veljajo za vse nadgradnje in/ali nove verzije in/ali kakršne koli druge spremembe aplikacije ViziDrive.</p>
                </Card.Text>
                <br/>
                <Card.Header>
                    <Card.Title>2. DEFINICIJE </Card.Title>
                </Card.Header>
                <br/>
 <Card.Text>
        <div className="first-1">  
<p className="p-1"> - Vizije Mobilnosti je družbe Vizije Mobilnosti d.o.o., Pot na Polane 22, 1351 Brezovica pri Ljubljani, matična številka: 8561796000 in/ali njene matične, hčerinske ali druge povezanimi družbami,</p>
<p className="p-1"> - mobilna aplikacija ViziDrive je mobilna aplikacija, ki si jo uporabnik namesti na svoj pametni telefon ali tablični računalnik prek spletne mobilne trgovine </p>
<p className="p-1"> - pametni telefon ali tablični računalnik je naprava, ki omogoča nameščanje in delovanje naprednih aplikacij, ki jih sicer običajni telefoni ne podpirajo, zlasti naprednejše možnosti dostopa do interneta, večopravilnost, povezljivost in uporabe naprednih funkcij</p>
<p className="p-1"> - spletna mobilna trgovina je digitalni način distribucije aplikacij, namenjena zagotavljanju oz. nameščanju programskih aplikacij za uporabnike in prenosu mobilnih aplikacij na pametne telefone</p>
<p className="p-1"> - registracija (uporabnika) je prvi vpis v bazo podatkov, ko uporabnik vpiše zahtevane podatke, poda soglasje k Splošnim pogojem uporabe mobilne aplikacije ViziDrive in Politiki varovanja zasebnosti uporabnika in s tem povezani obdelavi osebnih podatkov, naknadno potrdi uporabljen elektronski naslov in določi geslo za prijavo</p>
<p className="p-1"> - prijava (uporabnika) je vsak vstop v aplikacijo ViziDrive z uporabniškim imenom in geslom, navedenim v postopku registracije, ko so vsi potrebni podatki že vpisani v bazo in je uporabljen elektronski naslov potrjen s strani uporabnika; uporabnik geslo lahko naknadno spremeni</p>
<p className="p-1"> - uporabnik aplikacije ViziDrive je polno poslovno sposobna fizična oseba (potrošnik), ki se registrira za uporabo aplikacije ViziDrive </p>
<p className="p-1"> - cenik je samostojen dokument v fizični ali elektronski obliki, ki opredeljuje cene storitve polnjenja na polnilni infrastrukturi in/ali cene povezane z zagotavljanjem te storitve, določene s strani Vizij mobilnosti in/ali drugega lastnika polnilne infrastrukture in je objavljen na spletni strani, lahko pa je prikazan tudi v okviru mobilne aplikacije ViziDrive. </p>
</div>   
<br/>
<p>Uporabnik v primeru uporabe aplikacije ViziDrive, ob uporabi polnilne infrastrukture, zlasti za  pričetek in izvedbo polnjenja, potrjuje, da je seznanjen in soglaša s temi splošnimi pogoji uporabe mobilne aplikacije ViziDrive in pravili o uporabi polnilne infrastrukture in storitve polnjenja, objavljenimi na <a href="www.vizidrive.eu">www.vizidrive.eu</a> .</p>
    </Card.Text>
            <br/>
                <Card.Header>
                <Card.Title> 2. SPREMEMBE PODATKOV</Card.Title>
                </Card.Header>
            <br/>
                    <Card.Text>
                        <p>V primeru, da se osebni podatki iz točke 1 spremenijo, mora uporabnik sporočiti Vizijam mobilnosti spremembo v roku 30 dni od njenega nastanka, in sicer na e-mail naslov: info@vizidrive.eu, preko mobilne aplikacije ViziDrive ali pisno na naslov: Vizije mobilnosti d.o.o., Pot na Polane 22, 1351 Brezovica pri Ljubljani. </p>
                        <p>Sprememba podatkov, posredovanih preko mobilne aplikacije ViziDrive bo upoštevana takoj, sprememba podatkov, ki bo posredovana po navadni ali elektronski pošti, pa bo upoštevana najkasneje v roku 5 dni od dne prejema pisnega obvestila. </p>
                    </Card.Text>   
            <br/>
                    <Card.Header>
                <Card.Title>3. TEHNIČNE ZAHTEVE in PRENOS MOBILNE APLIKACIJE ViziDrive </Card.Title>
                </Card.Header>
            <br/>
                    <Card.Text>
                        <p>Za prenos in uporabo mobilne aplikacije ViziDrive je priporočljiva uporaba LTE, 3G ali 2,5G omrežja. V 2G je prenos in uporaba mobilne aplikacije ViziDrive mogoča, vendar se podatki v ozadju med mobilno aplikacijo in zalednim sistemom prenašajo počasneje, kar lahko vpliva na odzivnost mobilne aplikacije. </p>
                        <p>Mobilna aplikacija ViziDrive deluje na operacijskih sistemih: iOS (Apple) ali Android (Google). Zaradi tehničnih omejitev starejših verzij operacijskih sistemov določene aplikacije niso na voljo, funkcionalnosti ne delujejo pravilno ali pa prikaz funkcionalnosti ni mogoč.</p>
                        <p>Uporabnik lahko mobilno aplikacijo ViziDrive, glede na obstoječi pametni telefon in glede na razpoložljivost, prenese preko spletnih mobilnih trgovin za mobilne aplikacije, in sicer vedno na način in pod pogoji, ki so določeni za izbrane spletne mobilne trgovine: </p>
                        <p className='p-2'> - Apple iOS: iTunes Store, </p>
                        <p className='p-2'> - Google Android: Play Store. </p>
                        <p>Uporaba mobilne aplikacije je za uporabnika brezplačna, pri čemer je dolžan uporabnik plačati prenos podatkov, ki mu ga izbrani operater omrežja, preko katerega se povezuje na svetovni splet, zaračuna po svojem veljavnem ceniku. Podatkovni prenos podatkov preko mobilnega omrežja, ki je potreben za delovanje aplikacije, se lahko v tujem mobilnem omrežju obračuna po tarifnih postavkah za podatkovni prenos v tujem omrežju skladno z veljavnim cenikom mobilnega operaterja s katerim ima uporabnik sklenjeno pogodbo. </p>
                    </Card.Text>    
            <br/>
                    <Card.Header>
                <Card.Title>4.	FUNCKIONALNOSTI MOBILNE APLIKACIJE ViziDrive, REGISTRACIJA IN/ALI PRIJAVA </Card.Title>
                </Card.Header>
            <br/>
                    <Card.Text>
                        <p>Osnovne funkcionalnosti mobilne aplikacije ViziDrive so zlasti, vendar ne izključno:</p>
                        <p className='p-3'> - prikaz in iskanje polnilnic s pomočjo filtrov,</p>
                        <p className='p-3'> - prikaz informacij o izbrani polnilnici in razpoložljivosti priključkov,</p>
                        <p className='p-3'> - vnos in skeniranje kode za hitrejši pričetek polnjenja.</p>
                        
                        <p>Za uporabo osnovnih funkcionalnosti mobilne aplikacije ViziDrive registracija in/ali prijava ni potrebna.</p>
                        <p>Napredne funkcionalnosti mobilne aplikacije ViziDrive so zlasti, vendar ne izključno:</p>
                        <p className='p-3'> - izvedba polnjenja,</p>
                        <p className='p-3'> - dodajanje in urejanje plačilnih sredstev,</p>
                        <p className='p-3'> - pregled računov in zgodovine polnjenj na polnilni infrastrukturi,</p>
                        <p className='p-3'> - shranjevanje nastavitev aplikacije in filtrov iskanja polnilnic.</p>
                        <p>Registracija in prijava je potrebna za uporabo naprednih funkcionalnosti mobilne aplikacije ViziDrive in funkcionalnosti, vezanih na uporabniški račun uporabnika. Ob registraciji uporabnik potrdi, da je prebral veljavne splošne pogoje in politiko varovanja zasebnosti, se z njimi strinja in jih sprejema v celoti.</p>
                        <p>Registracijo in prijavo lahko uporabnik izvede na način, ki ga dopušča aplikacija. Podatki, ki so nujno potrebni za izvedbo postopka registracije in komunikacijo za poslovanje z uporabnikom so označeni s sporočilom, da je polje obvezno. V primeru, da uporabnik ne želi posredovati kateregakoli obveznega podatka ob registraciji, te ne bo mogel uspešno dokončati in uporaba mobilne aplikacije ViziDrive ne bo mogoča.</p>
                        <p>Uporabnik lahko kadarkoli prekliče svojo registracijo tako, da preklic registracije sporoči na e-mail naslov:<a href="mailto:info@vizidrive.eu"> info@vizidrive.eu</a> ali pisno na naslov VIZIJE MOBILNOSTI d.o.o., ViziDrive, Pot na Polane 22, 1351 Brezovica pri Ljubljani. Po prejemu preklica registracije se uporabniško ime izbriše iz seznama uporabnikov mobilne aplikacije ViziDrive.</p>
                    </Card.Text> 
                    <br/>
                    <Card.Header>
                <Card.Title>5. OMEJITEV ODGOVORNOSTI</Card.Title>
                </Card.Header>
            <br/>
                    <Card.Text>
                    <p>Vizije mobilnosti si prizadevajo za nemoteno delovanje mobilne aplikacije ViziDrive, vendar ne prevzemajo odgovornosti za škodo in/ali izgubo dohodka, ki bi uporabniku nastala zaradi morebitnega nedelovanja oz. nemožnosti uporabe mobilne aplikacije ViziDrive ali morebitne netočnosti informacij, njihove nemožnosti uporabe, pomanjkljivosti ali napake v njihovi vsebini.</p>
                    <p>Vizije mobilnosti ne odgovarjajo za morebitne napake in škodo in/ali izgubo dohodka, ki bi uporabniku nastala zaradi posredovanja napačnih podatkov in/ali drugega delovanja iz strani uporabnika npr. ob registraciji ali prijavi v aplikacijo ViziDrive. Vizije mobilnosti niso odgovorne za kakršno koli škodo in/ali izgubo dohodka, do katere lahko pride zaradi nepooblaščene uporabe uporabniškega računa ali gesla.</p>
                    <p>Za opravljanje nekaterih funkcionalnosti, ki so prikazane v mobilni aplikaciji ViziDrive, se uporablja neodvisne zunanje storitve in aplikacije. Za uporabo teh storitev veljajo pogoji uporabe zunanje storitve, ki jih določa lastnik storitve ali aplikacije, Vizije mobilnosti pa v zvezi s tem ne prevzemajo nobene odgovornosti in ne dajejo nobenih jamstev.</p>
                    <p>Aplikacija ViziDrive uporablja, vendar ne izključno, Google Maps Distance Matrix API, Maps SDK for Android, Maps Java Script API, Places API, Mobile Crash Reporting API in Apple Maps.</p>
                    <p>Za uporabo navedenih storitev, veljajo splošni pogoji družbe Google Inc. in Apple Inc. in so v delu, ki se nanašajo na uporabo teh storitev, sestavni del teh splošnih pogojev ter zavezujejo tudi uporabnike mobilne aplikacije ViziDrive. Dostopni so na naslednjih povezavah:</p>
                    <p><a href="https://www.google.com/intl/en/policies/terms/">https://www.google.com/intl/en/policies/terms/</a>,</p>
                    <p><a href="https://www.google.com/policies/privacy/ ">https://www.google.com/policies/privacy/ </a> in </p> 
                    <p><a href="https://www.apple.com/legal/internet-services/maps/terms-en.html">https://www.apple.com/legal/internet-services/maps/terms-en.html</a>.</p>
                    <p>Vizije mobilnosti imajo pravico uporabljati tudi druge podobne storitve; uporabniki so se s pogoji uporabe takšnih storitev dolžni seznaniti sami.</p>
                    <p>Vizije mobilnosti ne prevzemajo odgovornosti za napake v vsebini ter pravilnosti in točnosti objavljenih podatkov, do katerih je morebiti prišlo zaradi časovnih neusklajenosti, napak pri vnosu ali drugih nepredvidljivih vzrokov. Vizije mobilnosti ne odgovarjajo za obliko in vsebino podatkov, pridobljenih prek spletnih povezav, ki niso last Vizij mobilnosti. Uporabniki objavljeno vsebino uporabljajo na lastno odgovornost, pri čemer morajo poskrbeti tudi za ustrezno zaščito podatkov na svojem pametnem telefonu ali tabličnem računalniku.</p>
                    <p>Vizije mobilnosti si pridržujejo pravico, da kadarkoli brez predhodnega opozorila spremenijo, dodajo ali odstranijo vsebine, objavljene v mobilni aplikaciji ViziDrive.</p>
                   </Card.Text>
                   <br/>
                    <Card.Header>
                <Card.Title>6. NADGRADNJE IN NOVE VERZIJE MOBILNE APLIKACIJE ViziDrive </Card.Title>
                </Card.Header>
            <br/>
                   <Card.Text>
                     <p>Zaradi tehničnih ali funkcionalnih nadgradenj mobilne aplikacije ViziDrive, si Vizije mobilnosti pridržujejo pravico, da objavijo novo verzijo mobilne aplikacije ViziDrive. Uporabnik bo o novi verziji mobilne aplikacije ViziDrive obveščen ali preko mobilne aplikacije same ali preko trgovine iz katere je mobilna aplikacija naložena ali pa bo do posodobitve aplikacije na telefonu lahko prišlo tudi samodejno.</p>
                  </Card.Text>
              <br/>
                    <Card.Header>
                <Card.Title>7. OBDELAVA OSEBNIH PODATKOV</Card.Title>
                </Card.Header>
            <br/>
                   <Card.Text>
                     <p>Vizije mobilnosti obdelujejo osebne podatke v skladu z Uredbo (EU) 2016/679 Evropskega parlamenta in Sveta z dne 27. aprila 2016 o varstvu posameznikov pri obdelavi osebnih podatkov in o prostem pretoku takih podatkov ter o razveljavitvi Direktive 95/46/ES (GDPR) in nacionalnimi predpisi o varstvu osebnih podatkov.</p>
                     <p>Vrste osebnih podatkov, podlaga ter namen obdelave, spremembe podatkov, pravice uporabnika in rok hrambe so določeni v Politiki varovanja zasebnosti uporabnika, ki je dostopna na spletni strani <a href="www.vizidrive.eu">www.vizidrive.eu</a>.</p>
                  
                  </Card.Text>
              <br/>
              <Card.Header>
                <Card.Title>8. OBVEZNOSTI UPORABNIKA</Card.Title>
                </Card.Header>
            <br/>
                   <Card.Text>
                     <p>Uporabnik mobilne aplikacije ViziDrive se zavezuje, da je ne bo uporabljal za nezakonite ali splošnim pogojem nasprotujoče namene. Mobilne aplikacije ViziDrive ni dovoljeno uporabljati na način, ki bi lahko poškodoval, onemogočil, preobremenil ali škodil delovanju mobilne aplikacije ViziDrive ali družbi Vizije mobilnosti.</p>
                     <p>V primeru, da uporabnik zazna kakršnokoli nepooblaščeno uporabo uporabniškega imena ali gesla, je to dolžan nemudoma sporočiti Vizijam mobilnosti na elektronski naslov <a href="mailto:info@vizidrive.eu"> info@vizidrive.eu</a>. Vizije mobilnosti si pridržujejo pravico, da v primeru nedovoljene uporabe in/ali kakršnihkoli drugih zlorab pri dostopu oz. uporabi aplikacije s strani uporabnikov ali katerekoli drugih oseb in/ali v primeru suma zlorabe gesla, izvede vse ukrepe in druga dejanja, ki jih v skladu s svojo lastno poslovno presojo šteje za potrebne zaradi varstva svojih pravic in interesov in/ali zaradi varstva pravic in interesov kupcev in/ali uporabnikov in/ali zaradi varstva pravic in interesov katerih koli drugih oseb (zlasti, vendar ne izključno: preprečitev dostopa do aplikacije ViziDrive).</p>
                  
                  </Card.Text>
              <br/>
                 <Card.Header>
                 <Card.Title>9. PRAVICE INTELEKTUALNE LASTNINE </Card.Title>
                </Card.Header>
            <br/>
                   <Card.Text>
                     <p>Vsi podatki, fotografije in druge informacije na mobilni aplikaciji ViziDrive so predmet avtorske zaščite ali druge zaščite industrijske zaščite, kot jo opredeljuje veljavna zakonodaja, ki ureja varstvo avtorskih pravic in pravic industrijske lastnine. S prenosom mobilne aplikacije ViziDrive uporabnik pridobi neprenosljivo in neizključno pravico do uporabe mobilne aplikacije izključno na svojem pametnem telefonu ali tabličnem računalniku, zgolj za nekomercialne namene. Vizije mobilnosti na uporabnika ne prenašajo ostalih materialnih avtorskih pravic ali pravic industrijske lastnine. Vizije mobilnosti z uporabo mobilne aplikacije uporabniku ne dajejo pravice do uporabe blagovnih znamk, logotipov, celostne grafične podobe, ki je v lasti oz. imetništvu Vizij mobilnosti. Uporabnik zlasti ne sme izdelovati kopij mobilne aplikacije ViziDrive, namestiti aplikacije na strežnik ali drugo platformo, dajati mobilno aplikacijo ViziDrive v najem ali podeljevati podlicenc za njeno uporabo in predelati ali kako drugače posegati v delovanje aplikacije ViziDrive.</p> 
                  </Card.Text>

            <br/>
                 <Card.Header>
                 <Card.Title>10. PREHODNE IN KONČNE DOLOČBE</Card.Title>
                </Card.Header>
            <br/>
                   <Card.Text>
                     <p>Vsakokrat veljavni splošni pogoji so objavljeni na spletni strani <a href="www.vizidrive.eu">www.vizidrive.eu</a>, kjer se objavijo tudi morebitne spremembe in dopolnitve veljavnih splošnih pogojev, ki začnejo veljati z dnevom objave. Šteje se, da je bil uporabnik o spremembi oz. dopolnitvi, obveščen z dnem objave novih oz. spremenjenih ali dopolnjenih splošnih pogojev. V kolikor se uporabnik s spremenjenimi ali dopolnjenimi splošnimi pogoji ne strinjal, mora prenehati uporabljati mobilno aplikacijo ViziDrive in jo izbrisati iz svojega mobilnega telefona in/ali tabličnega računalnika. Morebitne spore bosta Vizije mobilnosti in uporabnik reševala sporazumno, če to ne bo mogoče, pa je za reševanje sporov pristojno stvarno pristojno sodišče v Ljubljani.</p> 
                     <p>Ti splošni pogoji pričnejo veljati z dnevom objave na spletni strani <a href="www.vizidrive.eu">www.vizidrive.eu</a></p>
                  </Card.Text>
    </Card>
  </Row>
  </Col>
  <br/>
</Container>
    )
}

export default Terms;
